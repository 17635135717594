import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { DecimalPipe } from '@angular/common';
import { Component, ElementRef, EventEmitter, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuExtraModalComponent } from 'src/app/shared/components/menu-extra-modal/menu-extra-modal.component';
import { NgbdSortableHeader } from 'src/app/shared/directives/NgbdSortableHeader';
import { CategoryService } from 'src/app/shared/service/category.service';
import { MenuItemsService } from 'src/app/shared/service/menu.items.service';
import { TableService } from 'src/app/shared/service/table.service';
import { CATEGORY, Category } from 'src/app/shared/tables/category';
import { MenuItemExtraOptionRequest } from 'src/app/shared/tables/menu-item-extra-option-request';
import { MenuItemExtraWithOptionsRequestModel } from 'src/app/shared/tables/menu-item-extra-request';
import {v4 as uuid} from 'uuid';

@Component({
  selector: 'app-menu-item-extras',
  templateUrl: './menu-item-extras.component.html',
  styleUrls: ['./menu-item-extras.component.scss'],
  providers: [MenuItemsService,TableService, DecimalPipe,CategoryService],
})
export class MenuItemExtrasComponent {
 public closeResult: string;

  searchText;
  tableItem$: Observable<Category[]>;
  total$: Observable<number>;
  @ViewChild('menuItemExtraOptionModal') menuItemExtraOptionModal: NgbModalRef;
  @ViewChild('menuItemExtraModal') menuItemExtraModal: NgbModalRef;
  @ViewChild('menuExtraModal') private modalComponent!: MenuExtraModalComponent;

  public _selectedId = new BehaviorSubject<string>('');

  public _extraOptionsToCreate = new BehaviorSubject<any[]>([]);

  public selectedId : EventEmitter<string> = new EventEmitter<string>();
  menuExtraSelected: any = {};
  extraOptionsToCreate: Array<MenuItemExtraOptionRequest> = new Array<MenuItemExtraOptionRequest>();
  extraOptionToCreate: MenuItemExtraOptionRequest = {name : '',description:'',menuItemExtraOptionEdited:false,id:'',price:0, optionsIndex:0, duration: null};

  @ViewChildren(NgbdSortableHeader) headers: QueryList<NgbdSortableHeader>;

  constructor(public service: TableService, private modalService: NgbModal, public menuItemService: MenuItemsService) {
    this.tableItem$ = service.tableItem$;
    this.total$ = service.total$;
    this.service.setUserData(CATEGORY)
  }
  
  open() {
    this.selectedId.emit("");
    this.modalComponent.open();
    console.log(this.menuItemExtraModal);
    this._selectedId.next('');
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  drop(event: CdkDragDrop<string[]>) {
    console.log('before: ' + this.extraOptionsToCreate[0].name);
    moveItemInArray(this.extraOptionsToCreate, event.previousIndex, event.currentIndex);
  }

  addExtraOptionToArray(){
    this.extraOptionToCreate.id = uuid();
    this.extraOptionsToCreate.push(this.extraOptionToCreate);

    if(this.extraOptionsToCreate[0] !== undefined){
      this._extraOptionsToCreate.next(this.extraOptionsToCreate)
      this.extraOptionToCreate = {name : '',description:'',id:'',menuItemExtraOptionEdited:false,price:0,optionsIndex:0, duration: null};
    }
  }

  createMenuItemExtraWithOptions(){
    this.addAndIndexOptions();
    this.menuExtraSelected.menuItemExtraId = uuid();
    console.log(this.menuExtraSelected);

    this.menuItemService.createExtraAndOptions(this.menuExtraSelected).subscribe(res =>{});
    // this.menuItemExtraModal.dismiss('Cross click');
  }

  addAndIndexOptions(){
    //index options based on array position
    this.extraOptionsToCreate.forEach(o => {o.optionsIndex = this.extraOptionsToCreate.indexOf(o)});

    this.menuExtraSelected.menuItemExtraOptions = new Array<MenuItemExtraOptionRequest>();
    this.menuExtraSelected.menuItemExtraOptions = this.extraOptionsToCreate;
  }

  onChange(event: any) {
    console.log('Toggle changed to: ', event.checked);
  }
  

  ngOnInit() {
  }
}
