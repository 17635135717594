<ng-template #menuItemModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">{{this.addOrEdit}} Menu Item</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click');clearState()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <ng-container *ngIf="menuItem$ | async as menuItemRes">
    <div class="modal-body">
        <form class="needs-validation">
            <div class="form">
                <div class="form-group">
                    <div class="box-input-file">
                        <input class="upload" type="file" (change)="readUrl($event,menuItemRes)">
                        <ng-container *ngIf="menuItemRes.menuItemImage">                        
                            <img class="imgUpload" alt="" [src]="getImage(menuItemRes.menuItemImage)" style="max-height: 120px;">
                        </ng-container>
                    </div>
                </div>
                <div class="form-group">
                    <label for="name" class="mb-1" >Menu Item Name :</label>
                    <input class="form-control" id="name" type="text" name="name" [(ngModel)]="menuItemRes.name" (ngModelChange)="changeMenuItem(menuItemRes)">
                </div>
                <div class="form-group">
                    <label for="description" class="mb-1" >Menu Item Description:</label>
                    <input class="form-control" id="description" type="text" name="description" [(ngModel)]="menuItemRes.description" (ngModelChange)="changeMenuItem(menuItemRes)">
                </div>
                <div class="form-group">
                    <label for="duration" class="mb-1" >Duration:</label>
                    <input class="form-control" id="duration" type="text" name="duration" [(ngModel)]="menuItemRes.duration" (ngModelChange)="changeMenuItem(menuItemRes)">
                </div>
                <div class="form-group">
                    <label for="price" class="mb-1" >Price :</label>
                    <input class="form-control" id="price" type="number" name="basePrice" [(ngModel)]="menuItemRes.basePrice" (ngModelChange)="changeMenuItem(menuItemRes)">
                </div> 
                <div class="form-group">
                    <mat-form-field>
                        <mat-label>Select an option</mat-label>
                        <mat-select [(value)]="menuItemRes.category" (valueChange)="changeMenuItem(menuItemRes)" >
                            <mat-option [value]="item.id" *ngFor="let item of menuCategories$ | async">
                                {{item.categoryName}}
                              </mat-option> 
                        </mat-select>
                      </mat-form-field>                      
                </div> 
                <div class="form-header">
                    <h5>Menu Item Extras</h5>
                </div>
                <button type="button" class="btn btn-primary" data-toggle="modal" data-original-title="test"
                data-target="#exampleModal" (click)="openMenuExtraOption(menuItemExtrasModal)" style="margin-bottom: 20px;">Add Menu Item Extra</button>

                <ng-container *ngIf="_menuItemExtras | async as extraOptionsRes">
                <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                    <div class="example-box" *ngFor="let extraOption of extraOptionsRes " cdkDrag>
                        {{extraOption.name}}-{{extraOption.isRequired}}-{{extraOption.optionsLimit}}
                        <a href="javascript:void(0)" (click)="deleteExtraToArray(extraOption)"><i class="fa fa-trash-o"></i></a>
                    </div>
                </div>
            </ng-container>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.dismiss('Cross click');" (mouseup)="createMenuItemExtraWithOptions()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click');clearState()">Close</button>
    </div>
</ng-container>
</ng-template>

<ng-template #menuItemExtrasModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title f-w-600" id="exampleModalLabel">Select Extra for Menu Item</h5>
        <button type="button" class="close" aria-label="Close"
            (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="table-responsive">
            <!-- <form>
                <div class="mb-3 row">
                    <label for="table-complete-search" class="col-xs-3 col-sm-auto col-form-label">Full text search:</label>
                    <div class="col-xs-3 col-sm-auto">
                      <input id="table-complete-search" type="text" class="form-control" name="searchTerm"  [(ngModel)]="searchText" autocomplete="off">
                    </div>
                </div>
              </form> -->
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th></th>
                        <th scope="col" sortable="product_name" (sort)="onSort($event)">Name</th>
                        <th scope="col" sortable="isRequired" (sort)="onSort($event)">Is Required</th>
                        <th scope="col" sortable="optionsLimit" (sort)="onSort($event)">Options Limit</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor = 'let menuItemExtra of menuItemExtras$ | async | filter:searchText'>                     
                         
                            <td><input type="checkbox" (change)="isChecked(menuItemExtra)" [checked]="isUsed(menuItemExtra)" /></td>
                            <td>{{ menuItemExtra.name }}</td>
                            <td>{{ menuItemExtra.isRequired }}</td>
                            <td>{{ menuItemExtra.optionsLimit }}</td>                        
                    </tr>
                </tbody>
            </table>
            <div class="d-flex justify-content-center p-2">
                <ngb-pagination
                   [collectionSize]="(total$ | async)!" [(page)]="menuItemService.page" [pageSize]="menuItemService.pageSize">
                   </ngb-pagination>
             </div> 
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="addExtraToArray()">Save</button>
        <button class="btn btn-secondary" type="button" data-dismiss="modal"
            (click)="modal.dismiss('Cross click')">Close</button>
    </div>
</ng-template>